<template>
  <div>
    <a-form class="simiot-nested-search-form" :form="form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="运营商账户名称">
            <a-input
              v-decorator="['name', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item label="运营商种类">
            <a-select
              v-decorator="['carrier_type']"
              allow-clear
            >
              <a-select-option value="china_mobile">
                中国移动
              </a-select-option>
              <a-select-option value="china_unicom">
                中国联通
              </a-select-option>
              <a-select-option value="china_telecom">
                中国电信
              </a-select-option>
              <a-select-option value="china_broadnet">
                中国广电
              </a-select-option>
              <a-select-option value="mix_carrier">
                融合运营商
              </a-select-option>
              <a-select-option value="international_carrier">
                国际运营商
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>

export default {
  name: 'SearchCarrierAccount',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'carrier_account_search' })
    }
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      this.$emit('submit', this.form.getFieldsValue())
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
