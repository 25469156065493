import request from '@/utils/request'
// 查询运营商账户
export function findCarrierAccounts(params) {
  return request({
    url: `/carrier_accounts`,
    method: 'get',
    params: params
  })
}

// 创建运营商账户
export function createCarrierAccount(data) {
  return request({
    url: `/carrier_accounts`,
    method: 'post',
    data
  })
}

// 查询单个运营商账户
export function findCarrierAccount(carrierAccountId) {
  return request({
    url: `/carrier_accounts/${carrierAccountId}`,
    method: 'get'
  })
}

// 查询单个运营商账户 name
export function findCarrierAccountName(carrierAccountId) {
  return request({
    url: `/carrier_accounts/${carrierAccountId}/name`,
    method: 'get'
  })
}

// 显示运营商账户form
export function findCarrierAccountForm(carrierAccountId) {
  return request({
    url: `/carrier_accounts/${carrierAccountId}/form`,
    method: 'get'
  })
}

// 更新运营商账户
export function updateCarrierAccount(carrierAccountId, data) {
  return request({
    url: `/carrier_accounts/${carrierAccountId}`,
    method: 'put',
    data
  })
}

// 批量删除运营商账户
export function deleteCarrierAccounts(carrierAccountIds) {
  return request({
    url: `/carrier_accounts/batch`,
    method: 'delete',
    data: carrierAccountIds
  })
}

// 导出运营商账户
export function exportCarrierAccounts(params) {
  return request({
    url: `/carrier_accounts/export`,
    method: 'post',
    params
  })
}

// 查询单个运营商账户 type
export function findCarrierType(carrierAccountId) {
  return request({
    url: `/carrier_accounts/${carrierAccountId}/carrier_type`,
    method: 'get'
  })
}
